#main-container{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* background: black; */
}

#shape-add-preview{
  pointer-events: none;
  opacity: 0.7;
}
